import React, { useState, useEffect, useContext } from "react";
import { BASE_URL, SRF_ID_BASE_IAS, SRF_ID_BASE_NABL, SRF_ID_BASE_NONABL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { generateNumber } from "../../../utils/utils";
import "./../srf.css";
import "driver.js/dist/driver.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
  Divider,
  createFilterOptions,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { showComponent } from "../../helper/helpers";
import { useParams } from "react-router-dom";

import {
  fetchClientIdList,
  useSrfDetails,
  fetchSettings,
  fetchCSRFFormData,
} from "./fetchHandlers";
import { DUCInwardDetails } from "./DUCInwardDetails";
import { handleUpdateSubmit, handleCreateSubmit } from "./submitHandlers";
import { editSRFGuide } from "../guide";
import CustomerAnnexure from "./customerAnnexure";
import { fetchAddresses } from "../../invoice/productDetails";
import CSRFForm from "./csrfForm";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import { SrfContext } from "./srf.context";
import { initialState } from "./srf.state";
import { useCreateReducer } from "../../../hooks/useCreateReducer.js";
import DucImages from "./ducImages.js";
import EditWrap from "../../commonComponent/editWrap.js";

const SRF_CAL_TYPE_BASE = {
  1: SRF_ID_BASE_NABL,
  2: SRF_ID_BASE_IAS,
  3: SRF_ID_BASE_NONABL,
};

const filter = createFilterOptions();

function SRFForm(props) {
  const params = useParams();
  const { setLoader } = props;
  const editSRFGuides = editSRFGuide();

  const [srfInstrumentsToDelete, setSrfInstrumentsToDelete] = useState([]);

  const [clientArray, setClientArray] = useState([]);
  const [countSRFInstruments, setCountSRFInstruments] = useState(null);
  const [latestSRFId, setLatestSRFId] = useState(null);
  const [allAddress, setAllAddress] = React.useState([]);
  const [allBillingAddress, setBillingAllAddress] = React.useState([]);
  const [isCustomerAnnexure, setIsCustomerAnnexure] = React.useState(false);
  const [isDucImages, setIsDucImages] = React.useState(false);
  const [isCSRF, setIsCSRF] = React.useState(false);
  // constants

  // data hooks
  const [srfDetails, updateSrfDetails, fetchSrfDetails] = useSrfDetails({
    id: params?.id,
    customerAnnextureFilePaths: [],
  });
  const [CSRFFormPayload, setCSRFFormPayload] = React.useState([]);
  const [CSRFFetchData, setCSRFFetchData] = React.useState({});
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [selectedCompanyHierarchy, setSelectedCompanyHierarchy] = React.useState({});
  const[companyHierarchyOptions, setCompanyHierarchyOptions]=React.useState([[],[]]);
  const[hierarchyResultArray, setHierarchyResultArray]=React.useState([[],[]]);
  const[clientContactData, setClientContactData]=React.useState([]);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const srfContextValue = useContext(SrfContext);
  const {
    state: {
      settings,
     ducInwardRows,
     ducInwardOriginalRows
    },
    dispatch,
  } = srfContextValue;

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  // helper functions
  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
      branch: clientArray[i].branchName,
      shippingAddress: clientArray[i].shippingAddress,
      contact: clientArray[i].contact,
    });
  }

  const getCountOfNablInstruments = () => {
    let query = {
      query : "select count(1) as count from srfInstruments where calibrationType = 1"
    }
    return axiosWithToken
      .post(BASE_URL + `dynamic`, query, {headers:{requestType: 'fetchData'}})
      .then((res) => {
         setCountSRFInstruments(res.data[0].count);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getNewCertId", "Edit SRF")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };


  const getNewSRFId = () => {
    let query = {
      query : `select count(1) as count from srfs where calType = ${srfDetails?.calType}`
    }
    return axiosWithToken
    .post(BASE_URL + `dynamic` , query , {headers:{requestType: 'fetchData'}})
      .then((res) => {
        const baseId = SRF_CAL_TYPE_BASE?.[srfDetails?.calType];
       
        if (res.data.length < 1) setLatestSRFId(0 + baseId);
        else setLatestSRFId(res.data[0].count + baseId);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getNewSRFId", "Edit SRF")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const validateData = () => {
    let isValid = true;

    if (!srfDetails?.clientId) {
      toast.error("Please select a client");
      isValid = false;
    }
    if (!srfDetails?.entryDate) {
      toast.error("Please select an entry date");
      isValid = false;
    }
    if (!srfDetails?.calType) {
      toast.error("Please select an Calibration Type");
      isValid = false;
    }
       if (!srfDetails?.serviceReqNumber) {
      toast.error("Service request number is not created/updated");
      isValid = false;
    }
    
    if (!srfDetails?.address) {
      toast.error("Please select an at least one address");
      isValid = false;
    }
    if (!srfDetails?.receiptDate) {
      toast.error("Please select an Receipt Date");
      isValid = false;
    }
    

    if (!ducInwardRows?.length) {
      toast.error("Please select at least one SRF Instrument!");
      isValid = false;
    }

    ducInwardRows.some((row, index) => {
      if (row.instrumentId == null || row.instrumentId == "") {
        toast.error(`Row ${index + 1}: Please select an Instrument`);
        isValid = false;
      }
      if (row.DUCID == null || row.DUCID == "") {
        toast.error(`Row ${index + 1}: Please add DUCID`);
        isValid = false;
      }
      if (row.locationOfInstrument == null || row.locationOfInstrument == "") {
        toast.error(`Please add DUC Location for row ${index + 1}`);
        isValid = false;
      }
      if (row.ranges == null || row.ranges == "") {
        toast.error(`Row ${index + 1}: Please add DUC Range(s)`);
        isValid = false;
      }
      if (row.lc == null || row.lc == "") {
        toast.error(`Row ${index + 1}: Please add Least Count(s)`);
        isValid = false;
      }
      if (row.make == null || row.make == "") {
        toast.error(`Row ${index + 1}: Please add DUC Make`);
        isValid = false;
      }
      if (row.calFrequency == null || row.calFrequency == "") {
        toast.error(
          `Row ${index + 1}: Please select the Calibration frequency`
        );
        isValid = false;
      }
      if (row.location == null || row.location == "") {
        toast.error(`Row ${index + 1}: Please select the Location`);
        isValid = false;
      }

      if(isValid === false){
        return true;
      }
    });

    return isValid;
  };


  const handleCompanyHierarchyChange = (label, value) => {
    setSelectedCompanyHierarchy((prev) => {
      const updatedHierarchy = {
        ...prev,
        [label]: value,
      };
  
      const newCompanyHierarchy = Object.values(updatedHierarchy).join(',');
      updateSrfDetails({ companyHierarchy: newCompanyHierarchy });
  
      return updatedHierarchy;
    });
  };
  

  const fetchClientSettingsData=async()=>{
    try{
      const sqlQuery = {
        query: `SELECT keyName, value,companyHierarchy  FROM clientSettings WHERE clientId = ${srfDetails.clientId} `,
      };

      const res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery,{headers: {requestType: 'fetchData'}});
     
      const response = res.data;

      // Use map to extract companyHierarchy and filter out empty strings and null values
      const HierarchyArray = response
        .map(ele => ele.companyHierarchy)
        .filter(hierarchy => hierarchy !== null && hierarchy !== "");
      
      // Use Set to ensure uniqueness and then convert back to array
      const uniqueArray = [...new Set(HierarchyArray)];
      
      // Split each element by "," and create an array of arrays
      const resultArray = uniqueArray.reduce((acc, element) => {
        const splitValues = element.split(',');
        splitValues.forEach((value, index) => {
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(value);
        });
        return acc;
      }, []);
      
      setHierarchyResultArray(resultArray)
    // Remove duplicates within each inner array
    const uniqueData = resultArray.map(innerArray => [...new Set(innerArray)]);
      setCompanyHierarchyOptions(uniqueData)

    }catch (error) {
      console.error(error);
    }
 
  }

  useEffect(() => {
  
    fetchClientIdList(setClientArray);
    fetchSettings([
      "srfInstrumentsAdditionalColumns",
      "DUCMasterStatus",
      "Certificate Number",
      "Certificate Number NABL",
      "Certificate Number IAS",
      "Certificate Number NONNABL",
      "serviceReqNumber",
      "serviceReqNumberNABL",
      "serviceReqNumberIAS",
      "serviceReqNumberNONNABL",
      "ULR number",
      "ULR ID",
      "Customer Annexure",
      "CSRFS",
      "companyHierarchy",
      "materialType",
      "DucImages"
    ], settings, dispatch);

    getCountOfNablInstruments();
  }, []);
  
  useEffect(() => {
    if (srfDetails?.calType) {
      getNewSRFId(); 
    }
  }, [srfDetails?.calType]);

  useEffect(() => {
    let serviceReqNumber;
      if (srfDetails?.calType == 1) {
        serviceReqNumber = settings?.serviceReqNumberNABL?.value;
      } else if (srfDetails?.calType == 2) {
        serviceReqNumber = settings?.serviceReqNumberIAS?.value;
      } else if (srfDetails?.calType == 3) {
        serviceReqNumber = settings?.serviceReqNumberNONNABL?.value;
      }
      // else{
      //   serviceReqNumber = settings?.serviceReqNumber?.value;
      // }
    if (serviceReqNumber && latestSRFId != null) {
      let generateSRFNumber=generateNumber(
        serviceReqNumber,
        `${latestSRFId}`,
        {
          branch: srfDetails?.branch,
          entryDate: srfDetails?.entryDate,
        }
      )

      if(params.id == 0){
      updateSrfDetails({
        serviceReqNumber: generateSRFNumber,
      });}
    }
  }, [srfDetails?.calType, srfDetails?.branch, srfDetails?.entryDate, latestSRFId]);

  useEffect(async () => {
    if(params.id !=0){
      const currentClient = clientArray?.find(client => client.id == srfDetails?.clientId);      
      updateSrfDetails({ branch: currentClient?.branchName || ""});
    }
    
    let Client = clientArray?.filter((e) => e.id == srfDetails?.clientId)[0];

    const addressIds = Client?.address;
    const shippingAddressIds = Client?.shippingAddress;

    let newAddressArray = await fetchAddresses(addressIds, shippingAddressIds);
    setAllAddress(newAddressArray);
  }, [srfDetails?.clientId, clientArray]);

  useEffect(async () => {
   
    let Client = clientArray?.filter((e) => e.id == srfDetails?.billingId)[0];

    const addressIds = Client?.address;
    const shippingAddressIds = Client?.shippingAddress;

    let newAddressArray = await fetchAddresses(addressIds, shippingAddressIds);
    setBillingAllAddress(newAddressArray);
  }, [srfDetails?.billingId, clientArray]);

  useEffect(() => {
    // updateSrfDetails({
    //   serviceReqNumber: generateNumber(settings.serviceReqNumber?.value),
    // });
    // updateSrfDetails({
    //   "Certificate Number": settings["Certificate Number"]?.value,
    //   "ULR number": settings["ULR number"]?.value,
    //   "ULR ID": settings["ULR ID"]?.value,
    // });

    if (settings["Customer Annexure"]?.value === "true") {
      setIsCustomerAnnexure(true);
    }
    if (settings["DucImages"]?.value === "true") {
      setIsDucImages(true);
    }
    if (settings["CSRFS"]?.value === "true") {
      setIsCSRF(true);
    }
  }, [settings]);

  useEffect(async () => {
    if(params?.id > 0) {
      await fetchSrfDetails(params?.id);
    }
    let CSRFData = await fetchCSRFFormData(params?.id);
    setCSRFFetchData(CSRFData);
  }, [params?.id]);


  useEffect(() => {
    // Set default values dynamically based on some logic
    
    const dynamicDefaults = {};
    settings?.companyHierarchy?.value?.split(",")?.forEach((labelName) => {
      dynamicDefaults[labelName] = "default";
    });
    setSelectedCompanyHierarchy(dynamicDefaults);
  }, [settings?.companyHierarchy]);

  const updateCompanyHierarchyOptions = () => {
    const selectedDivision = selectedCompanyHierarchy?.Division || "default";
  
    const allIndices = hierarchyResultArray[0]?.map((item, index) => 
      item === selectedDivision ? index : null
    ).filter(index => index !== null);
  
    const result = hierarchyResultArray.map((el, i) => 
      i === 0 ? el : el.filter((item, ind) => allIndices.includes(ind))
    );
  
    const uniqueData = result.map(innerArray => [...new Set(innerArray)]);
    setCompanyHierarchyOptions(uniqueData);
  };
  
  useEffect(() => {
    updateCompanyHierarchyOptions();
  }, [selectedCompanyHierarchy?.Division]);


  useEffect(()=>{
   if(srfDetails.clientId) fetchClientSettingsData()
  },[srfDetails.clientId])


  return (
    <EditWrap>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        { params.id == 0 ? "Create Inward" : "Edit Inward"}
      </Typography>
      <Tooltip title="User Guide" placement="top-start">
        <Button
          style={{ float: "right" }}
          onClick={() => {
            editSRFGuides.drive();
          }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
      <br />
      <hr />
      <Grid container spacing={2} >
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-companyName">
          <Autocomplete
            size="small"
            key={srfDetails?.clientId}
            options={pushCArray}
            value={
              (srfDetails?.clientId ? srfDetails?.clientId + ", " : "") +
              (srfDetails?.companyName || "")
            }
            isOptionEqualToValue={(option, value) => option.label === value}
            renderInput={(params) => <TextField {...params} label="Client *" />}
            onChange={async (event, newInputValue) => {
              newInputValue = newInputValue?.label;
              let clientDetails = {};

              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue?.split(",")[0])) {
                  if (client.address) {
                    clientDetails["address"] = client.address;
                    clientDetails["branch"] = client.branch;
                    clientDetails["shippingAddress"] = client.shippingAddress;
                    clientDetails["contact"] = client.contact;
                  } else {
                    clientDetails["address"] = "";
                  }
                }
              });
              const addressIds = clientDetails?.address;
              const shippingAddressIds = clientDetails?.shippingAddress;
              let newAddressArray = await fetchAddresses(
                addressIds,
                shippingAddressIds
              );
              setAllAddress(newAddressArray);
              // if (newAddressArray.length == 1)
              //   updateSrfDetails({ address: newAddressArray[0]?.address });
              updateSrfDetails({
                clientId: parseInt(newInputValue?.split(",")[0]),
                companyName: newInputValue?.split(",")[1],
                ...clientDetails,
                address: newAddressArray.length == 1 ? newAddressArray[0]?.id : null,
              });

              const clientContactData=clientDetails?.contact ? JSON?.parse(clientDetails?.contact):[]
              setClientContactData(clientContactData)
            }}
            disabled={params.id != 0}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-address">
          {allAddress?.length === 1 ||  params.id != 0 ?   (
            <TextField
             key={srfDetails?.address}
              value={
                allAddress?.length === 1
                  ? allAddress[0]?.address // If allAddress has only one element, use its address
                  : allAddress?.find(
                      (e) => e.id == (srfDetails?.address)?.split(",")?.[0]
                    )?.address || ""
              }
              label="Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
              options={allAddress}
              key={allAddress}
              value={allAddress?.find(
                (e) => e.id == (srfDetails?.address)) || null}
              size="small"
              getOptionLabel={(option) => option?.address}
              onChange={(event, value) => {
                updateSrfDetails({ address: value?.id });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Address *" variant="outlined" />
              )}
            />
          )}
        </Grid>

        
        <Grid item xs={12} sm={6} md={4} lg={3} >
         
          <Autocomplete
            options={clientContactData}
            key={srfDetails?.contactPersonDetails}
            // disabled={params.id != 0}
            value={srfDetails?.contactPersonDetails || null}
            size="small"
            getOptionLabel={(option) => option?.contactPersonName}
            onChange={(event, value) => {
              updateSrfDetails({ contactPersonDetails: value });
            }}
            renderInput={(params) => (
                <TextField {...params} label="Contact Person " variant="outlined" />
            )}
          />
 
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-dcno">
        <Autocomplete
          size="small"
          key={srfDetails?.calType}
          id="combo-box-demo"
          value={["", "NABL", "IAS", "NON NABL"][srfDetails?.calType] || ""}
          renderInput={(params) => (
            <TextField {...params} label="Calibration Type *" />
          )}
          options={[
            { id: 1, label: "NABL" },
            { id: 2, label: "IAS" },
            { id: 3, label: "NON NABL" },
          ]}
          onInputChange={(event, newInputValue) => {
            if (newInputValue === "NABL") {
              updateSrfDetails({ calType: 1 });
            } else if (newInputValue === "IAS") {
              updateSrfDetails({ calType: 2 });
            } else if (newInputValue === "NON NABL") {
              updateSrfDetails({ calType: 3 });
            }
          }}
          isOptionEqualToValue={(option, value) => option.label === value}
          disabled={params.id != 0}
        />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-serno">
          <TextField
            id="outlined-basic"
            key={srfDetails?.serviceReqNumber}
            label="Service request number *"
            size="small"
            fullWidth
            variant="outlined"
            defaultValue={srfDetails?.serviceReqNumber || ""}
            value={srfDetails?.serviceReqNumber || ""}
            onChange={(e) => {
              updateSrfDetails({ serviceReqNumber: e.target.value });
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-dcno">
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No."
            inputProps={{
              autoComplete: 'off',
            }}
            defaultValue={srfDetails?.dcNumber || ""}
            value={srfDetails?.dcNumber || ""}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfDetails({ dcNumber: e.target.value });
            }}
        />
        </Grid>
        </Grid>
      <br />{
        settings?.companyHierarchy?.value && (
        <>
          <Grid container spacing={2}>
          {settings?.companyHierarchy?.value?.split(",")?.map((labelName,index)=>(
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Autocomplete
                    key={srfDetails?.companyHierarchy?.split(",")?.[index]}
                    value={srfDetails?.companyHierarchy?.split(",")?.[index]}
                    onChange={(event, newValue) => {
                      handleCompanyHierarchyChange(labelName, newValue);
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return filtered;
                    }}
                    disabled={params.id != 0}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="uncertinity_edit_condcoeff"
                options={companyHierarchyOptions?.[index]?.includes('default') ? companyHierarchyOptions?.[index] : companyHierarchyOptions?.[index]?.concat('default')}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                    }}
                renderOption={(props, option) => <li {...props}>{option}</li>}
                    freeSolo={false}
                    renderInput={(params) => (
                      <TextField {...params} label={labelName} />
                    )}
                    size="small"
                  />
    
                </Grid>
              ))}
          </Grid>
          <br />
        </>
        )
      }

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-pono">
          <TextField
            id="outlined-basic"
            label="PO Number"
            inputProps={{
              autoComplete: 'off',
            }}
            value={srfDetails.poNumber || ""}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfDetails({ poNumber: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-billingname">
          <Autocomplete
            size="small"
            id="combo-box-demo"
            key={srfDetails.billingId}
            options={pushCArray}
            value={
              pushCArray.find(
                (ele) => ele.label?.split(",")?.[0] == srfDetails.billingId
              )?.label || null
            }
            renderInput={(params) => (
              <TextField {...params} label="Certificate Name" />
            )}
            onChange={async (event, newInputValue) => {
              newInputValue = newInputValue?.label;
              let clientDetails = {};

              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue?.split(",")[0])) {
                  if (client.address) {
                    clientDetails["address"] = client.address;
                    clientDetails["shippingAddress"] = client.shippingAddress;
                  } else {
                    clientDetails["address"] = "";
                  }
                }
              });
              const addressIds = clientDetails?.address;
              const shippingAddressIds = clientDetails?.shippingAddress;
              let newAddressArray = await fetchAddresses(
                addressIds,
                shippingAddressIds
              );
              setBillingAllAddress(newAddressArray);
             
            updateSrfDetails({
                billingAddress: newAddressArray.length == 1 ? newAddressArray[0]?.id : null,
                billingId: parseInt(newInputValue?.split(",")[0]),
              });
            }}
            // disabled={params.id != 0}
            // onInputChange={(event, newInputValue) => {
            //   updateSrfDetails({
            //     billingId: parseInt(newInputValue.split(",")[0]),
            //   });
            // }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-address">
          {allBillingAddress?.length === 1  ? (
            <TextField
            key={srfDetails?.billingAddress}
              value={
                allBillingAddress?.length === 1
                  ? allBillingAddress[0]?.address
                  : allBillingAddress?.find(
                      (e) => e.id == (srfDetails?.billingAddress)?.split(",")?.[0]
                    )?.address || ""
              }
              label="Address"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
            key={allBillingAddress}
              options={allBillingAddress}
              size="small"
              value={allBillingAddress?.find(
                (e) => e.id == (srfDetails?.billingAddress?.toString()?.split(",")?.[0] || "")
              ) || null}
              getOptionLabel={(option) => option?.address}
              onChange={(event, value) => {
                updateSrfDetails({ billingAddress: value?.id });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Certificate Address" variant="outlined" />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-entrydate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              key={srfDetails?.entryDate}
              label="Entry Date *"
              value={srfDetails?.entryDate}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ entryDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
              maxDate={new Date()}
              minDate={editAccess?.includes(8) ? null : new Date()}
              disabled={params.id != 0}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-dcdate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              key={srfDetails.dcDate}
              label="DC Dt./RGP Dt."
              value={srfDetails.dcDate}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ dcDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-podate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              key={srfDetails.poDate}
              label="PO Date"
              value={srfDetails.poDate}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ poDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-receiptdate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              key={srfDetails.receiptDate}
              label="Receipt date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                srfDetails.receiptDate
              }
              onChange={(newValue) => {
                updateSrfDetails({ receiptDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-comtdelvdate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              key={srfDetails.committedDeliveryDate}
              label="Committed delivery date"
              value={
                srfDetails.committedDeliveryDate
              }
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ committedDeliveryDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <hr />
      <DUCInwardDetails
        id={params?.id}
        srfInstrumentsToDelete={srfInstrumentsToDelete}
        setSrfInstrumentsToDelete={setSrfInstrumentsToDelete}
        srfDetails={srfDetails}
        updateSrfDetails={updateSrfDetails}
        openErrorModal={openErrorModal}
      />
      <hr />
      {isCSRF && (
        <>
          <CSRFForm
            pushCArray={pushCArray}
            latestSRFId={latestSRFId}
            setCSRFFormPayload={setCSRFFormPayload}
            CSRFFetchData={CSRFFetchData}
          />

          <Divider
            sx={{
              backgroundColor: "black",
              height: "2px",
              marginTop: "15px",
            }}
          />
        </>
      )}

      <br />
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      {isCustomerAnnexure && (
        <CustomerAnnexure
          customerAnnextureFilePaths={srfDetails?.customerAnnextureFilePaths}
          updateSrfDetails={updateSrfDetails}
          openErrorModal={openErrorModal}
        />
      )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      {isDucImages && (
        <DucImages
         ducImages={srfDetails?.ducImages}
          updateSrfDetails={updateSrfDetails}
          openErrorModal={openErrorModal}
        />
      )}
      </Grid>
      </Grid>
      <Divider
        sx={{
          backgroundColor: "black",
          height: "2px",
        }}
      />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>

        {showComponent("save") && (
          <>
           <Tooltip title={!(editAccess?.includes(1) || editAccess?.includes(0)) ? "You don't have access" : ''}>
           <Button
            id="editsrf-saveupdate"
            variant="contained"
            size="small"
            disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
            sx={{ m: 0 }}
            onClick={() => {
              if (!validateData()) return;
              if (srfDetails?.id > 0) {
                handleUpdateSubmit(
                  srfDetails,
                  ducInwardRows,
                  srfInstrumentsToDelete,
                  settings,
                  setLoader,
                  CSRFFormPayload,
                  CSRFFetchData?.id,
                  openErrorModal,
                  ducInwardOriginalRows,
                  countSRFInstruments
                );
              } else {
                handleCreateSubmit(
                  srfDetails,
                  ducInwardRows,
                  settings,
                  setLoader,
                  CSRFFormPayload,
                  openErrorModal,
                  latestSRFId,
                  updateSrfDetails,
                  countSRFInstruments,
                );
              }
            }}
          >
            {params.id != 0 ? "Update" : "Save"}
          </Button>
          </Tooltip>
          </>
         
        )}
        {false && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0, ml: 2 }}
            onClick={() => {
              console.log("incomplete functionality :(");
            }}
          >
            Print
          </Button>
        )}
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </EditWrap>
  );
}


export default function CreateSRF(props) {
  const contextValue = useCreateReducer({
    initialState,
  });
  return (
    <SrfContext.Provider value={{ ...contextValue }}>
      <SRFForm {...props} />
    </SrfContext.Provider>
  );
}